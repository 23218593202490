import { Route, Routes } from "react-router-dom";
import "./App.css";
import Landing from "./components/Landing";
import Information from "./components/Information";
import Login from "./components/Login";
import Signup from "./components/Signup";
import UserDashboard from "./components/Dashboard/UserDashboard";
import Protected from "./components/Protected";
import InConstruction from "./components/InConstruction";
import NotFound from "./components/404";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { Testimonials } from "./components/ReviewGroup";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/info" element={<Information />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route
          path="/dashboard"
          element={
            <Protected>
              <UserDashboard />
            </Protected>
          }
        />
        <Route path="/apply" element={<InConstruction />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
