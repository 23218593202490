import React from "react";
import MainHeader from "./MainHeader";
import "./Landing.css";
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { ReviewGroup } from "./ReviewGroup";
import { useNavigate } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { ReactTyped } from "react-typed";

const Landing = () => {
  const navigate = useNavigate();

  return (
    <Flex flexDirection="column" w="100%" alignItems="center">
      <MainHeader variant="landing" />
      <Flex flexGrow="1" alignItems="center">
        <Button
          bgRepeat="no-repeat"
          bgSize="100%"
          h="200px"
          w="200px"
          borderRadius="50%"
          size="lg"
          fontSize="25px"
          fontFamily="Vollkorn"
          bgColor="#D4C7D5"
          _hover={{ bg: "#bfb3c0" }}
          onClick={() => navigate("info")}
        >
          <ReactTyped
            strings={[
              "Start",
              "Comenzar",
              "Commencer",
              "Começar",
              "يبدأ",
              "Bẹrẹ",
              "Malite",
              "开始",
              "시작",
              "始める",
              "Simulan",
              "початок",
              "शुरू",
            ]}
            typeSpeed={100}
            loop
            backSpeed={20}
            showCursor={false}
          />
        </Button>
      </Flex>
      <Flex flexDirection="column" w="100%">
        <Box position="relative">
          <Divider variant="brand" />
          <AbsoluteCenter bg="white" px="4">
            <Icon as={GoDotFill} color="#8d77ab" />
          </AbsoluteCenter>
        </Box>
        <ReviewGroup />
      </Flex>
    </Flex>
  );
};

export default Landing;
