import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Logo from "./Logo";
import React from "react";
import { useNavigate } from "react-router-dom";

const LogoTitle = () => {
  return (
    <React.Fragment>
      <Logo w="100px" />
      <Box padding="4px" textAlign="center">
        <Text fontSize="4xl">Raven - Support Network</Text>
        <Text
          fontSize="xs"
          fontFamily="Cardo"
          fontStyle="italic"
          marginTop="-8px"
        >
          #1 community for International Students and Newcomers in Canada
        </Text>
      </Box>
    </React.Fragment>
  );
};

const HeaderVariant = ({ variant }) => {
  const navigate = useNavigate();
  if (variant === "landing") {
    return <LogoTitle />;
  } else {
    return (
      <Flex alignItems="center" onClick={() => navigate("/")}>
        <LogoTitle />
      </Flex>
    );
  }
};

const MainHeader = ({ variant }) => {
  const navigate = useNavigate();

  return (
    <Flex
      alignItems="center"
      fontFamily="EB Garamond"
      justifyContent={variant === "dashboard" ? "center" : "space-between"}
      w="100%"
      paddingLeft="12px"
      paddingRight="32px"
      paddingTop="8px"
      position="sticky"
      top="0"
      bg="white"
      zIndex="100"
    >
      <HeaderVariant variant={variant} />
      {variant !== "dashboard" ? (
        <Button
          as="a"
          colorScheme="yellow"
          marginTop="8px"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
      ) : null}
    </Flex>
  );
};

export default MainHeader;
